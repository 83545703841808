const processENV = process.env;
console.log(process.env.REACT_APP_API_URL)
const serverURL = processENV.REACT_APP_API_URL || "/api/";
const basePath = processENV.REACT_APP_BASE_URL || "/";
const s3Url = processENV.REACT_APP_S3_URL || "";

export const KEY_STRING = "X3YvtRPmZ819fxLQebnja";
export const imageBaseUrl = ""

export const apiConfig = {
  baseURL: `${serverURL}/api/`,
  serverURL,
  timeout: 300000,
  basePath,
  s3Url
};

export const IMAGE_COUNT = 4;
